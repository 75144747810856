import { PublicLibraryProgramList } from '../../PublicLibrary/PublicLibraryPrograms';
import { type ProgramDetailsProps } from './types';

export function ProgramDetailsRelatedPrograms(props: ProgramDetailsProps) {
  const { relatedPrograms } = props;

  if (!relatedPrograms?.length) return null;
  return (
    <section className='w-300 flex flex-col gap-5'>
      <div className='text-3.5xl font-bold'>Related Programs</div>

      <div className={`w-full flex items-center flex-wrap gap-2.5`}>
        <PublicLibraryProgramList
          programs={relatedPrograms}
          onClick={props.onClickProgram}
        />
      </div>
    </section>
  );
}
