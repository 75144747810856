import useSWR from 'swr';

import { FAQGroupUtils } from '../../../../src/components/FAQ';
import { FAQItem } from '../../../../src/components/FAQ/FAQList';
import { apiService } from '../../../../src/services/api-service';
import { type ProgramDetailsProps } from './types';

export const PROGRAM_DETAILS_FAQS_ID = 'program-details-faqs';

export function ProgramDetailsFAQs(props: ProgramDetailsProps) {
  const { program, anonymous } = props;

  const groupIds = !anonymous
    ? program.marketingMetadata?.faqGroupIds
    : program.marketingMetadata?.anonymousFAQGroupIds;

  const { data: faqs } = useSWR(['/faqs', groupIds], async () => {
    if (!groupIds) return [];
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: groupIds.join(','),
      })
      .next();
    return sharedAssets
      .map((m) => FAQGroupUtils.FromSharedAsset(m))
      .flatMap((g) => g.faqs);
  });

  if (!faqs?.length) return null;

  return (
    <section className='w-full' id={PROGRAM_DETAILS_FAQS_ID}>
      <div className='text-3.5xl font-bold'>FAQs</div>
      <div className='mt-5 w-full flex flex-col gap-2'>
        {faqs.map((faq) => (
          <FAQItem key={faq.id} faq={faq} />
        ))}
      </div>
    </section>
  );
}
