import { DoubleDownArrow } from '../../../../src/components/icons/Arrows';
import { PlayIcon } from '../../../../src/components/icons/PlayIcon';
import { CompaniesUsingLunaParkV2 } from '../../../../src/components/Marketing/CompaniesUsingLunaPark';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import { MediaUtils } from '../../../../src/utils/media';
import { useTriggerMediaModal } from '../../MediaModal';
import { PROGRAM_DETAILS_FAQS_ID } from './ProgramDetailsFAQs';
import { type ProgramDetailsProps } from './types';

function ProgramTrailer(props: ProgramDetailsProps) {
  const { program } = props;

  const triggerMediaModal = useTriggerMediaModal();
  const media = fromMediaDTO(program.basicSettings?.trailer?.media);
  const poster = MediaUtils.PickMediaUrl(media, {
    videoThumbnail: 'first',
  });
  if (!poster || !media) return null;

  const handleClick = () => {
    triggerMediaModal({ media });
  };

  return (
    <div
      className='relative w-full rounded-xl overflow-hidden'
      style={{
        aspectRatio: '16/9',
      }}
      onClick={handleClick}
    >
      <img
        src={poster}
        alt=''
        className='w-full h-full rounded-xl object-cover'
      />

      <div className='absolute inset-0 bg-lp-black-005 flex justify-center items-center'>
        <button
          type='button'
          className='w-14 h-14  rounded-full flex justify-center items-center'
          style={{
            background: 'rgba(111, 111, 111, 1)',
          }}
        >
          <PlayIcon className='w-7 h-7 fill-current text-white' />
        </button>
      </div>
    </div>
  );
}

export function ProgramDetailsSidePanel(props: ProgramDetailsProps) {
  return (
    <div className='w-80 flex-none -mt-19.5'>
      <div className='sticky top-12 w-full flex flex-col gap-2.5'>
        <ProgramTrailer {...props} />

        {props.sideAction}

        <button
          type='button'
          onClick={() => {
            document
              .getElementById(PROGRAM_DETAILS_FAQS_ID)
              ?.scrollIntoView({ behavior: 'smooth' });
          }}
          className='btn-secondary rounded-xl w-full h-6 flex justify-center items-center gap-1.5 text-3xs font-medium'
        >
          See FAQs <DoubleDownArrow className='w-2.5 h-2.5 fill-current' />
        </button>

        <div className='mt-2.5 flex flex-col items-center gap-2'>
          <div className='text-sms text-white'>Used by 90,000+ people</div>
          <CompaniesUsingLunaParkV2 width={300} align='center' />
        </div>
      </div>
    </div>
  );
}
