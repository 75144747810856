import { useSearchParams } from '@remix-run/react';
import { useRef } from 'react';

import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../../../src/components/ConfirmCancelModalContext';
import { MarkdownBody } from '../../../../src/components/MarkdownEditor';
import { useLiveCallback } from '../../../../src/hooks/useLiveCallback';
import { useOutsideClick } from '../../../../src/hooks/useOutsideClick';
import { ProgramDetailsFAQs } from './ProgramDetailsFAQs';
import { ProgramDetailsHeader } from './ProgramDetailsHeader';
import { ProgramDetailsHowItWorks } from './ProgramDetailsHowItWorks';
import { ProgramDetailsRelatedPrograms } from './ProgramDetailsRelatedPrograms';
import { ProgramDetailsSidePanel } from './ProgramDetailsSidePanel';
import { ProgramDetailsStats } from './ProgramDetailsStats';
import { ProgramsDetailsWhatCustomersSay } from './ProgramDetailsWhaCustomersSay';
import { type ProgramDetailsProps } from './types';

function ProgramDescription(props: ProgramDetailsProps) {
  const { program } = props;

  if (program.basicSettings?.richDescription) {
    return <MarkdownBody body={program.basicSettings.richDescription} />;
  }

  return (
    <div className='text-base text-white whitespace-pre-wrap'>
      {program.basicSettings?.longDescription}
    </div>
  );
}

export function ProgramDetailsCard(props: ProgramDetailsProps) {
  return (
    <div className='w-full bg-modal text-white flex flex-col items-center'>
      <ProgramDetailsHeader {...props} />

      <div className='w-full px-5 pb-20 flex justify-center'>
        <div className='max-w-300 flex flex-col gap-15'>
          <div className={`flex gap-15 w-full`}>
            <div className='flex-1 overflow-hidden py-10 flex flex-col gap-10'>
              <ProgramDescription {...props} />
              <ProgramDetailsHowItWorks {...props} />
              <ProgramDetailsStats {...props} />
              <ProgramsDetailsWhatCustomersSay {...props} />
              <ProgramDetailsFAQs {...props} />
            </div>

            <ProgramDetailsSidePanel {...props} />
          </div>

          <ProgramDetailsRelatedPrograms {...props} />
          {props.activelyUsedBy}
        </div>
      </div>
    </div>
  );
}

export function ProgramDetailsModal(
  props: ProgramDetailsProps & {
    onClose: () => void;
  }
) {
  const { onClose } = props;

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => onClose());

  return (
    <ConfirmCancelModalProvider>
      <div
        className={`fixed z-50 top-15 bottom-0 w-screen bg-lp-black-004 pt-5 px-20 flex justify-center items-center`}
      >
        <div
          ref={ref}
          className='w-full max-w-[1280px] min-w-[1080px] h-full bg-modal overflow-y-auto scrollbar border border-secondary rounded-t-2.5xl'
        >
          <ProgramDetailsCard {...props} />

          <ConfirmCancelModalRoot />
        </div>
      </div>
    </ConfirmCancelModalProvider>
  );
}

export function useSearchParamProgramId(key = 'program-id') {
  const [params, setParams] = useSearchParams();

  const programId = params.get(key);
  const setProgramId = useLiveCallback((programId: string | null) => {
    setParams((prev) => {
      if (programId) {
        prev.set(key, programId);
      } else {
        prev.delete(key);
      }
      return new URLSearchParams(prev);
    });
  });

  return [programId, setProgramId] as const;
}
