import { type ModelsProgramMarketingStat } from '@lp-lib/api-service-client/public';

import { getStaticAssetPath } from '../../../../src/utils/assets';
import { type ProgramDetailsProps } from './types';

function StatBox(props: { stat: ModelsProgramMarketingStat }) {
  const { stat } = props;
  return (
    <div
      className='w-full min-h-45 rounded-2.5xl pt-10.5 p-2.5 flex flex-col items-center gap-6'
      style={{
        background:
          'linear-gradient(180deg, rgba(153, 153, 153, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%)',
      }}
    >
      <div className='text-4.25xl font-bold text-tertiary'>{stat.value}</div>
      <div className='text-sms text-center'>{stat.description}</div>
    </div>
  );
}

export function ProgramDetailsStats(props: ProgramDetailsProps) {
  const { program } = props;

  if (!program.marketingMetadata?.stats?.length) return null;
  return (
    <div className='w-full flex'>
      <div className='flex-none w-15 h-full flex items-center'>
        <img
          src={getStaticAssetPath('images/program-details/stats-left.png')}
          alt=''
          className='w-11 h-32 object-contain'
        />
      </div>
      <div className='flex-1 w-full flex items-center gap-2.5'>
        {program.marketingMetadata.stats.map((stat) => (
          <StatBox key={stat.id} stat={stat} />
        ))}
      </div>
      <div className='flex-none w-15 h-full flex justify-end items-center'>
        <img
          src={getStaticAssetPath('images/program-details/stats-right.png')}
          alt=''
          className='w-11 h-32 object-contain'
        />
      </div>
    </div>
  );
}
