import { useCallback } from 'react';

import { type Media, MediaType } from '@lp-lib/media';

import { useAwaitFullScreenConfirmCancelModal } from '../../src/components/ConfirmCancelModalContext';
import { ModalWrapper } from '../../src/components/ConfirmCancelModalContext/ModalWrapper';
import { CloseIcon } from '../../src/components/icons/CloseIcon';
import { MediaPickPriorityHD, MediaUtils } from '../../src/utils/media';

export function useTriggerMediaModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: { media: Media }) => {
      const { media } = props;

      const url = MediaUtils.PickMediaUrl(media, {
        priority: MediaPickPriorityHD,
      });
      if (!url) return null;

      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-3/4 max-w-[#1280]'
            innerClassName='rounded-xl'
            borderStyle='gray'
            onClose={p.internalOnConfirm}
          >
            <div className='relative w-full group'>
              <div className='absolute z-10 right-2.5 top-2.5 group-hover:opacity-100 opacity-0'>
                <button
                  type='button'
                  className='w-7 h-7 flex justify-center items-center rounded-full bg-black bg-opacity-50 hover:bg-opacity-75'
                  onClick={p.internalOnConfirm}
                >
                  <CloseIcon className='w-4 h-4 fill-current text-white' />
                </button>
              </div>

              <div
                className='w-full rounded-xl overflow-hidden'
                style={{
                  aspectRatio: '16/9',
                }}
              >
                {media?.type === MediaType.Video ? (
                  <video
                    className='w-full h-full object-cover'
                    src={url}
                    controls
                    autoPlay
                  />
                ) : (
                  <img
                    src={url}
                    alt=''
                    className='w-full h-full object-cover'
                  />
                )}
              </div>
            </div>
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}
