import { useRef, useState } from 'react';

import { type ModelsProgramMarketingTutorial } from '@lp-lib/api-service-client/public';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '../../../../src/components/icons/Arrows';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import { MediaPickPriorityHD, MediaUtils } from '../../../../src/utils/media';
import { type ProgramDetailsProps } from './types';

function TutorialImage(props: { tutorial: ModelsProgramMarketingTutorial }) {
  const { tutorial } = props;

  const url = MediaUtils.PickMediaUrl(fromMediaDTO(tutorial.media?.media), {
    priority: MediaPickPriorityHD,
  });
  if (!url) return null;

  return (
    <img
      src={url}
      alt=''
      className='w-full object-contain'
      style={{
        aspectRatio: '16/9',
      }}
    />
  );
}

function TutorialCarousel(props: {
  tutorials: ModelsProgramMarketingTutorial[];
}) {
  const { tutorials } = props;

  const gap = 20;

  const contentRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState(0);

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const offset =
    current * (contentRef.current?.clientWidth ?? 0) + gap * current;

  return (
    <div className='w-full'>
      <div className='mb-5 w-full flex justify-center items-center'>
        <div
          className='w-[85%] flex items-center transition-transform ease-in-out duration-300'
          style={{
            transform: `translateX(-${offset}px)`,
            gap,
          }}
        >
          {tutorials.map((tutorial, index) => (
            <div
              key={tutorial.id}
              className={`w-full flex-none text-xl font-bold ${
                index !== current ? ' opacity-0' : 'opacity-100'
              }`}
            >
              {tutorial.description}
            </div>
          ))}
        </div>
      </div>

      <div className='relative w-full flex justify-center items-center'>
        <div className='w-full overflow-hidden program-details-mask-image flex justify-center items-center'>
          <div
            className='w-[85%] flex items-center transition-transform ease-in-out duration-300'
            style={{
              transform: `translateX(-${offset}px)`,
              gap,
            }}
            ref={contentRef}
          >
            {tutorials.map((tutorial) => (
              <div className='w-full flex-none' key={tutorial.id}>
                <TutorialImage key={tutorial.id} tutorial={tutorial} />
              </div>
            ))}
          </div>
        </div>

        {current > 0 && (
          <button
            type='button'
            onClick={prev}
            className={`
            absolute left-1
            flex-none btn
            w-12.5 h-12.5 bg-lp-black-004 rounded-full 
            text-secondary hover:text-white
            border border-secondary
            flex justify-center items-center
          `}
          >
            <ArrowLeftIcon className='w-6 h-6 fill-current' />
          </button>
        )}

        {current < tutorials.length - 1 && (
          <button
            type='button'
            onClick={next}
            className={`
            absolute right-1
            flex-none btn 
            w-12.5 h-12.5 bg-lp-black-004 rounded-full
            text-secondary hover:text-white
            border border-secondary
            flex justify-center items-center
          `}
          >
            <ArrowRightIcon className='w-6 h-6 fill-current' />
          </button>
        )}
      </div>
    </div>
  );
}

export function ProgramDetailsHowItWorks(props: ProgramDetailsProps) {
  const { program } = props;

  if (!program.marketingMetadata?.tutorials?.length) return null;
  return (
    <section className='w-full flex flex-col gap-5'>
      <div className='text-3.5xl font-bold'>How It Works</div>
      <TutorialCarousel tutorials={program.marketingMetadata.tutorials} />
    </section>
  );
}
