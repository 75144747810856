import { useEffect, useRef } from 'react';
import { useHydrated } from 'remix-utils/use-hydrated';

import { MediaFormatVersion } from '@lp-lib/media';

import { CopyButton } from '../../../../src/components/common/CopyButton';
import { CopyLinkIcon } from '../../../../src/components/icons/CopyLinkIcon';
import { SlackIcon } from '../../../../src/components/icons/SlackIcon';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../../src/utils/media';
import { type ProgramDetailsProps } from './types';

function Background(props: ProgramDetailsProps) {
  const { program } = props;

  const media = fromMediaDTO(program.basicSettings?.background?.media);

  const thumbnail =
    MediaUtils.PickMediaUrl(media, {
      priority: ImagePickPriorityLowToHigh,
    }) || '';
  const raw =
    MediaUtils.PickMediaUrl(media, {
      priority: [MediaFormatVersion.Raw],
    }) || '';

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (raw === thumbnail) return;

    const imgRaw = new Image();
    imgRaw.src = raw;
    imgRaw.onload = () => {
      if (!imgRef.current) return;

      imgRef.current.src = raw;
      imgRef.current.classList.remove('filter', 'blur');
    };
  }, [raw, thumbnail]);

  if (!thumbnail || !raw) return null;
  return (
    <img
      ref={imgRef}
      src={thumbnail}
      className={`absolute w-full h-full object-cover ${
        raw !== thumbnail ? 'filter blur' : ''
      }`}
      alt=''
    />
  );
}

function CopyLinkButton() {
  const isHydrated = useHydrated();
  const href = isHydrated ? window.location.href : '';

  return (
    <CopyButton
      copiedText={href}
      className='
        btn w-9 h-9 bg-main-layer hover:bg-lp-gray-003 text-white 
        rounded-lg border border-secondary
        flex items-center justify-center
        '
    >
      <CopyLinkIcon className='w-4 h-4 fill-current' />
    </CopyButton>
  );
}

export function ProgramDetailsHeader(props: ProgramDetailsProps) {
  const { program, breadcrumbs } = props;

  const headline =
    program.basicSettings?.headline || program.basicSettings?.shortDescription;
  return (
    <div className={`relative w-full h-100`}>
      <Background {...props} />

      <div className='absolute w-full h-full p-5 flex items-center justify-center'>
        <div className='relative w-full max-w-300 h-full flex items-center px-5 py-10'>
          <div className='absolute top-0 left-0 w-full flex justify-between items-center'>
            {breadcrumbs || <div></div>}
            <CopyLinkButton />
          </div>
          <div className='max-w-144 flex flex-col gap-5'>
            <div className='flex-none flex items-center gap-2 text-black'>
              <div className='text-xl font-bold'>{program.name}</div>
              <div className='w-17 h-4 flex justify-center items-center bg-white rounded-1.5lg text-3xs font-medium'>
                PROGRAM
              </div>
            </div>
            {headline && (
              <div
                className='text-5xl leading-[3.5rem] font-semibold text-white line-clamp-3'
                style={{
                  color: program.basicSettings?.headlineColor || undefined,
                }}
              >
                {headline}
              </div>
            )}
            <div className='flex-none text-base text-icon-gray'>
              Works with <SlackIcon className='w-4 h-4 inline' /> Slack
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
